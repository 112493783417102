import { createQueryKeys } from '@lukemorales/query-key-factory';
import { Type } from '@sinclair/typebox';
import { TypeCompiler } from '@sinclair/typebox/compiler';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { httpChecked } from 'util/fetch';

const schema = Type.Union([
  Type.Object({ logged_in: Type.Readonly(Type.Literal(false)) }),
  Type.Object({
    logged_in: Type.Readonly(Type.Literal(true)),
    session_expires_in_minutes: Type.Readonly(Type.Number()),
  }),
]);

const checker = TypeCompiler.Compile(schema);

const queries = createQueryKeys('logged_in_status', {
  check: {
    queryKey: null,
    queryFn: ({ signal }) =>
      httpChecked.get('/api/v2/logged_in_status', checker, {
        signal,
        skipLoadingIndicator: true,
      }),
  },
});

export function useWillBeLoggedOut() {
  const { data } = useQuery({
    ...queries.check,
    staleTime: 10_000,
    refetchInterval: 60_000,
    select(loggedInStatus) {
      if (!loggedInStatus.logged_in) {
        return false;
      }

      const sessionExpiresInMinutes = loggedInStatus.session_expires_in_minutes;

      return sessionExpiresInMinutes <= 10;
    },
  });

  return data;
}

export function useIsLoggedIn() {
  const { data } = useQuery({
    ...queries.check,
    staleTime: 10_000,
    select: (loggedInStatus) => loggedInStatus.logged_in,
  });

  return data;
}

export function usePostponeLogout() {
  const queryClient = useQueryClient();

  return useMutation({
    mutationKey: ['postpone_logout'],
    mutationFn: (postponedExpiryTime: boolean) =>
      httpChecked.post('/api/v2/logged_in_status', checker, {
        body: {
          logged_in_status: { postponed_expiry_time: postponedExpiryTime },
        },
      }),
    // ideally, we would use onSuccess and populate the cache, but the data
    // returned by the server is stale (`session_expires_in_minutes` is not
    // updated). It's still here as the backend might get fixed at some point
    // (and the data is more up to date, technically), but we still invalidate.
    onSuccess(data) {
      queryClient.setQueryData(queries.check.queryKey, data);
    },
    onSettled() {
      queryClient.invalidateQueries(queries.check.queryKey);
    },
  });
}
