import { createQueryKeys } from '@lukemorales/query-key-factory';
import { Type } from '@sinclair/typebox';
import { TypeCompiler } from '@sinclair/typebox/compiler';
import { useQuery } from '@tanstack/react-query';
import { httpChecked, queryClient } from 'util/fetch';
import { useIsLoggedIn } from './LoggedInStatus';

const schema = Type.Array(Type.String());

const checker = TypeCompiler.Compile(schema);

const queries = createQueryKeys('addons', {
  active: {
    queryKey: null,
    queryFn: ({ signal }) =>
      httpChecked.get('/addons/active', checker, { signal }),
  },
});

function useActiveAddons() {
  const isLoggedIn = useIsLoggedIn();

  return useQuery({
    ...queries.active,
    select: (data) => new Set(data),
    staleTime: 30_000,
    refetchInterval: 60_000,
    enabled: isLoggedIn === true,
  });
}

export function useIsAuditLogEnabled() {
  const { data } = useActiveAddons();

  if (!data) {
    return false;
  }

  return data.has('Audit Log');
}

export function useIsGenerativeAiEnabled() {
  const { data } = useActiveAddons();

  if (!data) {
    return false;
  }

  return data.has('Generative AI');
}

export function prefetchActiveAddons() {
  return queryClient.prefetchQuery({ ...queries.active, staleTime: 60_000 });
}
